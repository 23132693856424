'use client'

import { cn } from '@/lib/utils/utils'
import { useMounted } from '@/lib/utils/utils-client'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { MdClose, MdOutlineMenu } from 'react-icons/md'
import { getLocalizedUri, I18nValue, useI18nValue } from '../i18n/lang-context'
import { LangMenuFlatHTML } from '../i18n/lang-toggle'
import { NavItem } from '../layout/nav-item'
import { ThemeToggle } from '../theme/theme-toggle'
import { Button } from '../ui/button'
import { Flex, FlexCol } from '../ui/flex'
import { LBAction } from './lb/lb-action'
import { LogoHorizontal } from './logo'


export function Menu({ navItems = [], langCode }: { navItems?: NavItem[], langCode: string }) {

  const i18nValue = useI18nValue()
  const [shown, setShown] = useState(false)

  useEffect(() => {
    document.body.style.overflow = shown ? 'hidden' : 'unset'
  }, [shown])

  const mounted = useMounted()
  if (!mounted) return null

  const MenuLinks = ({ title, items }: { title?: string | I18nValue, items: NavItem[] }) => {
    return (
      <FlexCol className='w-full gap-4 pt-6' onClick={() => setShown(false)}>
        {title && (<div className='flex w-full items-center justify-center px-4 pb-2 text-xl font-bold opacity-75'>
          {typeof title === 'string' ? title : i18nValue(title)}
        </div>)}
        {items?.map((item, index) => item.href && (
          <Link key={'_' + (Math.random() * 1000) + index} href={getLocalizedUri(item.href)} className='flex w-full items-center justify-center px-4'>
            <Button aria-label='MENU_LINK_BUTTON' variant={'link'} className={cn('text-[18px] font-medium text-primary dark:text-primaryBright')} onClick={() => setShown(false)}>
              {typeof item.title === 'string' ? item.title : i18nValue(item.title)}
            </Button>
          </Link>
        ) || (item.subs && ((<MenuLinks key={'_' + (Math.random() * 1000) * 1000} title={item.title} items={item.subs} />))))}
      </FlexCol>
    )
  }

  return (
    <>
      <MdOutlineMenu size={32} className='text-primary dark:text-primaryBright' onClick={() => setShown(s => !s)} />
      <div className='fixed right-0 top-0 z-40 flex h-screen w-full flex-col overflow-y-auto overscroll-none bg-background transition-all' style={{
        transform: 'translateX(' + (shown ? '0' : '100%') + ')'
      }}>
        <Flex className='h-16 items-center justify-between p-4'>
          <LogoHorizontal onClick={() => setShown(false)} langCode={langCode} />
          <MdClose size={32} className='text-primary dark:text-primaryBright' onClick={() => setShown(false)} />
        </Flex>
        <MenuLinks items={navItems} />
        <LangMenuFlatHTML className={'py-8'} />
        <Flex className='w-full justify-center pb-8'><ThemeToggle /></Flex>
        <div className='w-full px-4 text-base'>
          <LBAction className='w-full' />
        </div>
      </div>
    </>
  )
}
